<mat-form-field [floatLabel]="'always'"
  class="select-input"
  [class.native-select-input]="useNativeSelect"
  [class.hide-label]="hideLabel"
  [attr.aria-label]="ariaLabel">

  @if (!!label) {
    <mat-label [class.sr-only]="hideLabel">
      {{ label }}
    </mat-label>
  }

  @if (!!icon) {
    <mat-icon class="icon"
      [ngClass]="icon"/>
  }

  @if (useNativeSelect) {
    <select matNativeControl
      [formControl]="formControlRef"
      [required]="required"
      [class.has-icon]="!!icon">
      @for (option of (dynamicLabel ? optionsWithDynamicLabel : options); track option) {
        <option [value]="option.value ? option.value : option.name"
          [attr.aria-selected]="formControlRef?.value === (option.value ? option.value : option.name)">
          {{ dynamicLabel ? (option.name | async) : option.name }}
        </option>
      }
    </select>
  } @else {
    <mat-select [formControl]="formControlRef"
      [required]="required"
      [class.has-icon]="!!icon">
      @for (option of (dynamicLabel ? optionsWithDynamicLabel : options); track option) {
        <mat-option [value]="option.value ? option.value : option.name"
          [disabled]="option.disabled"
          [matTooltip]="option.disabledReason">
          {{ dynamicLabel ? (option.name | async) : option.name }}
        </mat-option>
      }
    </mat-select>
  }

  @if (!noErrorMargin) {
    <mat-error>
      <accredible-input-error>
        <ng-content/>
      </accredible-input-error>
    </mat-error>
  }

</mat-form-field>
